import styled, { css } from "styled-components";
import { Button, Link } from "@mui/material";
import React from "react";
import { Fade } from "react-awesome-reveal";
import { ArrowRight, Logo, QuoteLeft, RetroFace } from "Icons";
import { COLORS } from "styles/Colors";
import Fonts from "styles/Fonts";
import { IncomingMessage } from "http";
import { mainModule } from "process";

interface Props {
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
}

export const Retrospect = ({
  isMobile = false,
  isTablet = false,
  isDesktop = false,
}: Props) => {
  return (
    <Wrapper>
      <Header isMobile={isMobile}>
        <Logo></Logo>
        {!isMobile && (
          <Button
            variant="contained"
            sx={{
              backgroundColor: `${COLORS.brand1}`,
              borderRadius: "8px",
              textTransform: "capitalize",
            }}
            href="https://tally.so/r/w2By8b"
            target="_blank"
          >
            <HeaderButtonText> Closed Beta 신청하기</HeaderButtonText>
          </Button>
        )}
      </Header>
      <Main>
        <Section
          className="retro-intro"
          isMobile={isMobile}
          style={isMobile ? { marginTop: "10px" } : {}}
        >
          <Fade direction="up" triggerOnce={true} duration={1500}>
            <div className="intro-main">
              <Fonts.H1 className="intro-main-title">
                하루 10분, 나를 만나는 시간
              </Fonts.H1>
            </div>
            <div className="intro-detail">
              <div
                style={{
                  textAlign: "center",
                  margin: isMobile ? "20px 0px" : "40px 0px",
                  fontSize: `min(4vw, 24px)`,
                  lineHeight: `min(6.5vw, 36px)`,
                }}
              >
                {/* SLASH는 개인 생산성을 폭발적으로 높여주는
                <br></br> <strong>GTD 방법론</strong> 기반 업무관리 툴입니다. */}
                내 목표, 내가 되고 싶은 모습, 내가 오늘 배운 것.
                <br></br>
                <strong>SLASH</strong>는 진짜 나를 만나는 &apos;회고&apos;를
                돕습니다.
              </div>

              <Button
                variant="contained"
                size="large"
                href="https://tally.so/r/w2By8b"
                target="_blank"
                sx={{
                  backgroundColor: `${COLORS.brand1}`,
                  marginTop: isMobile ? "40px" : "50px",
                  padding: "16px",
                  borderRadius: "8px",
                  textTransform: "capitalize",
                }}
              >
                <MainButtonText>Closed Beta 신청하기</MainButtonText>
              </Button>
            </div>
          </Fade>

          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{ width: "100%", marginTop: "140px" }}
          >
            <RetrospectContainer isMobile={isMobile}>
              <div className="retrospect-content">
                <div>
                  <RetroFace
                    width={isMobile ? 35 : 52}
                    height={isMobile ? 35 : 53}
                  ></RetroFace>
                  <Fonts.H1
                    style={{
                      fontSize: `min(5vw, 36px)`,
                      lineHeight: `min(7vw, 50px)`,
                      marginTop: "5px",
                    }}
                  >
                    프로로서 고민이 있나요? <br></br> 회고가 필요한 순간일 수
                    있어요.
                  </Fonts.H1>
                </div>
                <div
                  style={
                    isMobile
                      ? { marginLeft: "0px", marginTop: `min(12vw, 60px)` }
                      : { marginLeft: "60px" }
                  }
                >
                  <div className="retrospect-content-subtext">
                    &quot;시간이 너무 빠르게만 느껴져요.&quot;
                  </div>
                  <div className="retrospect-content-subtext">
                    &quot;내가 잘 성장하고 있는지 모르겠어요.&quot;
                  </div>
                  <div className="retrospect-content-subtext">
                    &quot;업무를 개선하고 싶은데 급한 일만 하게 돼요.&quot;
                  </div>
                </div>
              </div>
            </RetrospectContainer>
          </Fade>
        </Section>

        <Section className="retro-main" isMobile={isMobile}>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={{ width: "100%" }}
          >
            <MainContent
              isMobile={isMobile}
              style={{
                backgroundImage: isMobile
                  ? `url(${require("../images/mobileSlash.png")})`
                  : `url(${require("../images/banner.png")})`,
                backgroundSize: isMobile ? "contain" : "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="main-text">
                <div
                  style={{
                    fontSize: "min(6.8vw, 60px)",
                    lineHeight: "min(9.5vw, 50px)",
                  }}
                >
                  성공하는 사람이 한다는 &apos;회고&apos;,
                </div>
                <div
                  className="intro-main-logo"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Logo
                    width={`min(24vw, 212px)`}
                    height={`min(12vw, 70px)`}
                    fill={`${COLORS.gray900}`}
                  ></Logo>
                  <div
                    style={{
                      marginLeft: "3px",
                      fontSize: "min(6.8vw,60px)",
                      lineHeight: "min(9.5vw, 50px)",
                    }}
                  >
                    와 함께 하면 쉬워요.
                  </div>
                </div>
              </div>
            </MainContent>
          </Fade>
        </Section>

        <Section className="retro-content" isMobile={isMobile}>
          <Survey isDesktop={isDesktop} isMobile={isMobile} isTablet={isTablet}>
            <div className="survey-text-container">
              <Fade direction="up" triggerOnce={true}>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      color: `${COLORS.brand1}`,
                      fontSize: "24px",
                    }}
                  >
                    회고 서베이
                  </Fonts.H1>
                </div>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      fontSize: "min(6.9vw,36px)",
                      lineHeight: "min(9.5vw, 50px)",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {isMobile
                      ? ` 내 성장을 돕는 회고 \n 질문들을 만나보세요`
                      : ` 내 성장을 돕는 회고 질문들을 만나보세요`}
                  </Fonts.H1>
                </div>
                <div
                  className="feature-text"
                  style={{
                    fontSize: "min(3.8vw, 24px)",
                    lineHeight: "min(6vw, 36px)",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {isMobile
                    ? `내가 되고 싶은 나를 정리하고, \n 내게 필요한 질문들을 찾아보세요.`
                    : `내가 되고 싶은 나를 정리하고, \n 내게 필요한 질문들을 찾아보세요.`}
                </div>
              </Fade>
            </div>

            <div className="feature-img-container">
              <Fade direction="up" triggerOnce={true} duration={1500}>
                <div className="feature-img">
                  <img
                    src={require("../images/recommendedSurvey.png")}
                    width={isDesktop ? "90%" : "90%"}
                    height="auto"
                  ></img>
                </div>
                <div className="feature-img">
                  <img
                    src={require("../images/dailyRetro.png")}
                    width={isDesktop ? "90%" : "90%"}
                    height="auto"
                  ></img>
                </div>
              </Fade>
            </div>
          </Survey>
        </Section>

        <Section className="retro-content" isMobile={isMobile}>
          <Reminder
            isMobile={isMobile}
            isTablet={isTablet}
            isDesktop={isDesktop}
          >
            <div className="reminder-text-container">
              <Fade direction="up" triggerOnce={true}>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      color: `${COLORS.brand1}`,
                      fontSize: "24px",
                    }}
                  >
                    리마인더
                  </Fonts.H1>
                </div>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      fontSize: "min(7vw,36px)",
                      lineHeight: "min(9.5vw, 50px)",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {isMobile
                      ? " 가장 중요한 약속, '나'를 \n 만나는 시간을 정해보세요"
                      : `가장 중요한 약속, '나'를 만나는 시간을 정해보세요`}
                  </Fonts.H1>
                </div>
                <div
                  className="feature-text"
                  style={{
                    fontSize: "min(3.8vw, 24px)",
                    lineHeight: "min(6vw, 36px)",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {isMobile
                    ? `회고하고 싶은 시간, 알림 받고 싶은 채널에 맞춰 \n 정기 회고 서베이를 보내드려요.`
                    : `회고하고 싶은 시간, 알림 받고 싶은 채널에 맞춰 \n 정기 회고 서베이를 보내드려요.`}
                </div>
              </Fade>
            </div>

            <div className="feature-img-container">
              <Fade direction="up" triggerOnce={true} duration={1500}>
                <div className="feature-img">
                  <img
                    src={require("../images/surveyTime.png")}
                    width={isDesktop ? "90%" : "90%"}
                    height="auto"
                  ></img>
                </div>
                <div className="feature-img">
                  <img
                    src={require("../images/surveyChannel.png")}
                    width={isDesktop ? "90%" : "90%"}
                    height="auto"
                  ></img>
                </div>
              </Fade>
            </div>
          </Reminder>
        </Section>

        <Section className="retro-content" isMobile={isMobile}>
          <Archive
            isMobile={isMobile}
            isTablet={isTablet}
            isDesktop={isDesktop}
          >
            <div className="archive-text-container">
              <Fade direction="up" triggerOnce={true}>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      color: `${COLORS.brand1}`,
                      fontSize: "24px",
                    }}
                  >
                    회고 아카이브
                  </Fonts.H1>
                </div>
                <div className="feature-text">
                  <Fonts.H1
                    style={{
                      fontSize: "min(7vw,36px)",
                      lineHeight: "min(9.5vw, 50px)",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {isMobile
                      ? "하나씩 회고록을 쌓아가 \n 나답게 성장하세요."
                      : "하나씩 회고록을 쌓아가 나답게 성장하세요."}
                  </Fonts.H1>
                </div>
                <div
                  className="feature-text"
                  style={{
                    fontSize: "min(3.8vw, 24px)",
                    lineHeight: "min(6vw, 36px)",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {isMobile
                    ? `시간이 지날수록 쌓이는 나만의 기록으로 \n 내 중심을 되찾을 수 있어요.`
                    : `시간이 지날수록 쌓이는 나만의 기록으로 \n 내 중심을 되찾을 수 있어요.`}
                </div>
              </Fade>
            </div>

            <div className="feature-img-container">
              <Fade direction="up" triggerOnce={true} duration={1500}>
                <div className="feature-img">
                  <img
                    src={require("../images/archiveRecord.png")}
                    width={isDesktop ? "90%" : "90%"}
                    height="auto"
                  ></img>
                </div>
                <div className="feature-img">
                  <img
                    src={require("../images/archiveSelf.png")}
                    width={isDesktop ? "90%" : "90%"}
                    height="auto"
                  ></img>
                </div>
              </Fade>
            </div>
          </Archive>
        </Section>

        <Section className="retro-register" isMobile={isMobile}>
          <Fade
            direction="up"
            triggerOnce={true}
            duration={1500}
            style={
              isDesktop
                ? {
                    width: "100%",
                    paddingBottom: "200px",
                    display: "flex",
                    justifyContent: "center",
                  }
                : { width: "100%" }
            }
          >
            <Register
              isDeskTop={isDesktop}
              isMobile={isMobile}
              isTablet={isTablet}
            >
              <div className="register">
                <div className="register-sentence">
                  {isMobile ? (
                    <QuoteLeft width={29} height={22}></QuoteLeft>
                  ) : (
                    <QuoteLeft></QuoteLeft>
                  )}
                  <div
                    style={isMobile ? { marginLeft: 8 } : { marginLeft: 20 }}
                  >
                    <div className="register-sentence-title">
                      {isMobile
                        ? `생각대로 살지 않으면\n사는대로 생각하게 된다.`
                        : `생각대로 살지 않으면 사는대로 생각하게 된다.`}
                    </div>
                    <Fonts.Body1 className="register-sentence-name">
                      폴 부르제
                    </Fonts.Body1>
                  </div>
                </div>
                <div className="register-title">
                  {isMobile
                    ? `SLASH와 함께 \n '생각대로' 살아보세요.`
                    : `SLASH와 함께 '생각대로' 살아보세요.`}
                </div>

                <Button
                  variant="contained"
                  size="large"
                  href="https://tally.so/r/w2By8b"
                  target="_blank"
                  sx={{
                    backgroundColor: `${COLORS.brand1}`,
                    padding: "16px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                  }}
                >
                  <MainButtonText>Closed Beta 신청하기</MainButtonText>
                </Button>
              </div>
            </Register>
          </Fade>
        </Section>
      </Main>

      <Footer isMobile={isMobile}>
        <div style={{ width: isMobile ? "100%" : "70%" }}>
          <Logo fill={COLORS.white}></Logo>

          <div className="footer-context" style={{ whiteSpace: "pre-line" }}>
            {isMobile
              ? ` Live as you think, \n not think as you live.`
              : ` Live as you think, not think as you live.`}
          </div>

          <div className="divider"></div>
          <div className="footer-bar">
            <div className="footer-bar-text">
              <Link
                underline="none"
                href="http://policy.doslash.io/"
                target="_blank"
                sx={{ cursor: "pointer", color: `${COLORS.white}` }}
              >
                Terms of Service
              </Link>
              <Link
                underline="none"
                href="#"
                target="_blank"
                sx={{ cursor: "pointer", color: `${COLORS.white}` }}
              >
                Privacy Policy
              </Link>
              <Link
                underline="none"
                href="https://www.sigcrew.com/"
                target="_blank"
                sx={{ cursor: "pointer", color: `${COLORS.white}` }}
              >
                Company Information
              </Link>
            </div>
            <div
              className="footer-bar-copyright"
              style={{ whiteSpace: "pre-line" }}
            >
              {isMobile
                ? `Copyright © 2023 SIG. \n All Rights Reserved.`
                : `Copyright © 2023 SIG. All Rights Reserved.`}
            </div>
          </div>
        </div>
      </Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const Header = styled.div<{ isMobile?: boolean }>`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 400px;
  padding: 24px 0px;
  margin-bottom: 38px;
  position: sticky;
  top: 0;
  background-color: ${COLORS.white};
  z-index: 500;

  ${(props) =>
    props.isMobile &&
    css`
      padding: 28px 20px;
      justify-content: flex-start;
    `}
`;

const Main = styled.div`
  .retro-intro {
    margin-top: 100px;
  }

  .retro-content {
    padding: 0px 20px;
  }

  .retro-timeboxing {
    padding: 0px 20px;
  }

  .retro-linkage {
    padding: 0px 20px;
  }
`;

const Section = styled.div<{ isMobile?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:not(:first-child) {
    margin-top: 260px;
  }

  .intro-main {
    height: fit-content;
    display: flex;
    align-items: center;
  }

  .intro-main-title {
    color: ${COLORS.gray900};
    font-size: min(7.5vw, 60px);
  }

  .intro-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .main-img {
    width: 1080px;
    height: 700px;
    background-color: ${COLORS.sub3};
    margin-bottom: 160px;
  }

  .feature-text {
    margin-bottom: 14px;

    &:last-child {
      font-size: 24px;
      font-weight: 500;
      line-height: 36px;
      letter-spacing: 0em;
      color: ${COLORS.gray900};
    }
  }

  .intro-main-logo {
    margin-top: 12px;
  }

  ${(props) =>
    props.isMobile &&
    css`
      &:not(:first-child) {
        margin-top: 180px;
      }

      .retro-content {
        margin-top: 180px;
      }

      .intro-main-logo {
        margin-top: 0px;
      }

      .feature-text {
        margin-bottom: 10px;
      }
    `}
`;

const MainContent = styled.div<{ isMobile?: boolean }>`
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  padding: 130px 20px;

  .main-text {
    font-size: 60px;
    font-weight: 500;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* > div {
      :nth-child(1) {
        font-family: Pretendard;
        font-size: 60px;
        font-weight: 500;
        line-height: 72px;
        letter-spacing: 0em;
        text-align: center;
        margin-bottom: 20px;
      }
    }

    > div {
      :last-child {
        font-family: Pretendard;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: center;
        color: ${COLORS.gray700};
      } */
  }

  ${(props) =>
    props.isMobile &&
    css`
      .main-text {
        > div {
          :nth-child(1) {
            font-size: 7vw;
            line-height: 72px;
            margin-bottom: 0px;
          }
        }

        > div {
          :last-child {
            font-size: min(3.8vw, 24px);
            line-height: min(6vw, 36px);
          }
        }
      }
    `}
`;

const Survey = styled.div<{
  isDesktop?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
}>`
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .survey-text-container {
    text-align: center;
  }

  .feature-img {
    margin-top: 60px;
  }

  .feature-img {
    ${(props) =>
      props.isMobile &&
      css`
        margin-top: 20px;
      `}

    ${(props) =>
      props.isTablet &&
      css`
        margin-top: 60px;
      `}
  }

  ${(props) =>
    (props.isMobile || props.isTablet) &&
    css`
      flex-direction: column;
      align-items: center;
      .survey-text-container {
        text-align: center;
      }
    `}

  .feature-img {
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
      props.isMobile &&
      css`
        margin-top: 25px;
      `}

    ${(props) =>
      props.isTablet &&
      css`
        margin-top: 60px;
      `}
  }

  .feature-img-container {
    margin-top: 60px;
    display: flex;
  }

  ${(props) =>
    !props.isDesktop &&
    css`
      flex-direction: column;
      align-items: center;

      .feature-img-container {
        margin-top: 0px;
        flex-direction: column;
      }
    `}
`;

const Reminder = styled.div<{
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
}>`
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .feature-img-container {
    margin-top: 60px;
    display: flex;
  }
  .reminder-text-container {
    text-align: center;
  }

  .feature-img {
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
      props.isMobile &&
      css`
        margin-top: 25px;
      `}

    ${(props) =>
      props.isTablet &&
      css`
        margin-top: 60px;
      `}
  }

  ${(props) =>
    !props.isDesktop &&
    css`
      flex-direction: column;
      align-items: center;
      .reminder-text-container {
        text-align: center;
      }

      .feature-img-container {
        margin-top: 0px;
        flex-direction: column;
      }
    `}
`;

const Archive = styled.div<{
  isMobile?: boolean;
  isTablet?: boolean;
  isDesktop?: boolean;
}>`
  max-width: 1080px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .feature-img-container {
    margin-top: 60px;
    display: flex;
  }
  .archive-text-container {
    text-align: center;
  }

  .feature-img {
    display: flex;
    align-items: center;
    justify-content: center;
    ${(props) =>
      props.isMobile &&
      css`
        margin-top: 25px;
      `}

    ${(props) =>
      props.isTablet &&
      css`
        margin-top: 60px;
      `}
  }

  ${(props) =>
    !props.isDesktop &&
    css`
      flex-direction: column;
      align-items: center;
      .archive-text-container {
        text-align: center;
      }

      .feature-img-container {
        margin-top: 0px;
        flex-direction: column;
      }
    `}
`;

const Register = styled.div<{
  isDeskTop?: boolean;
  isMobile?: boolean;
  isTablet?: boolean;
}>`
  width: 80%;
  padding: 130px 20px;
  background-color: ${COLORS.sub3};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props) =>
    props.isDeskTop &&
    css`
      border-radius: 32px;
    `}
  ${(props) =>
    props.isMobile &&
    css`
      padding: 100px 20px;
    `}

  .register {
    height: 405px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .register-sentence {
    display: flex;
    padding: 48px;
    background-color: ${COLORS.white};
    border-radius: 32px;
    box-shadow: 0 4px 4px 0 ${COLORS.gray300};
    ${(props) =>
      props.isMobile &&
      css`
        padding: 32px;
      `}
  }

  .register-title {
    font-size: min(8vw, 48px);
    font-weight: 700;
    line-height: min(12vw, 48px);
    letter-spacing: 0em;
    text-align: center;
    white-space: pre-wrap;
  }

  .register-context {
    font-size: min(5.5vw, 24px);
    font-weight: 500;
    line-height: 36px;
    letter-spacing: -0.022em;
    text-align: center;
    color: ${COLORS.gray600};
    margin: 28px 0px;
    white-space: pre-wrap;
  }

  .register-sentence-title {
    font-family: "Gowun Batang", serif;
    font-size: min(4vw, 32px);
    white-space: pre-wrap;
  }

  .register-sentence-name {
    color: ${COLORS.brand1};
    font-size: min(3vw, 24px);
    margin-top: 10px;
    ${(props) =>
      props.isMobile &&
      css`
        margin-top: 2px;
      `}
  }

  ${(props) =>
    (props.isMobile || props.isTablet) &&
    css`
      width: 100%;
    `}
`;

const Footer = styled.div<{ isMobile?: boolean }>`
  height: fit-content;
  background-color: ${COLORS.black};
  color: ${COLORS.white};
  padding: 153px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .footer-context {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;
    margin-top: 30px;
  }

  .divider {
    width: 100%;
    height: 1px;
    background-color: ${COLORS.white};
    margin: 50px 0px;
  }

  .footer-bar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: -apple-system;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }

  .footer-bar-text {
    order: 1;
    width: 300px;
    display: flex;
    justify-content: space-between;
  }

  ${(props) =>
    props.isMobile &&
    css`
      padding: 60px 40px;

      .footer-bar {
        flex-direction: column;
      }

      .footer-bar-text {
        order: 0;
        margin-bottom: 12px;
        flex-direction: column;
      }
    `}
`;

const HeaderButtonText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
`;

const MainButtonText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
`;

const RetrospectContainer = styled.div<{ isMobile?: boolean }>`
  width: 100%;
  padding: 130px 20px;
  background-color: ${COLORS.gray100};
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .retrospect-content {
    display: flex;
    justify-content: space-between;
    ${(props) =>
      props.isMobile &&
      css`
        flex-direction: column;
      `}
  }

  .retrospect-content-subtext {
    background-color: ${COLORS.sub3};
    padding: min(3vw, 24px) min(5vw, 50px) min(3vw, 24px) min(5vw, 50px);
    text-align: center;
    margin-bottom: min(4vw, 20px);
    border-radius: 16px;
    font-size: min(3.8vw, 24px);
    line-height: 36px;
    color: ${COLORS.gray900};
  }
`;
