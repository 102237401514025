import App from "App";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import ReactPixel from "react-facebook-pixel";

const PIXEL_ID = process.env.REACT_APP_FACEBOOK_PIXEL_ID;
if (PIXEL_ID) {
  ReactPixel.init(PIXEL_ID);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
