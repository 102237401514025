import React, { SVGProps } from "react";

export const Linkage = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.2612 15.3526L9.89753 16.7163C8.39122 18.2226 8.39078 20.665 9.89709 22.1713C11.4034 23.6776 13.8463 23.6772 15.3526 22.1709L16.7149 20.8073M15.3518 11.2611L16.7155 9.89742C18.2218 8.39111 20.6637 8.39138 22.17 9.89769C23.6763 11.404 23.6762 13.8462 22.1699 15.3525L20.807 16.7162"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.71484 25.9546L7.32199 24.3889L8.76842 22.9632L9.49163 22.2502"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.2866 5.71436L24.6794 7.28014L23.233 8.70588L22.5098 9.41876"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.339 22.7501V20.8215M22.2676 18.893H24.1961M10.6961 11.1787H8.76758M12.6247 7.32153V9.2501"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
