import React, { SVGProps } from "react";

export const Graph = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2604_108647)">
        <path
          d="M17 7V16H26"
          stroke="#1F2023"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17 25C21.9706 25 26 20.9706 26 16C26 11.0294 21.9706 7 17 7C12.0294 7 8 11.0294 8 16C8 20.9706 12.0294 25 17 25Z"
          stroke="#1F2023"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2604_108647">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0.666016)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
