import React, { SVGProps } from "react";

export const Recurring = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.5492 19.7177C23.4441 24.5049 18.8365 27.6324 13.9793 26.8916C9.1225 26.1512 5.65581 21.7933 6.02724 16.8946C6.39867 11.9949 10.4819 8.20947 15.3949 8.20947"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4866 5L18.939 8.23084M18.939 8.23084L15.3945 11.6817M18.939 8.23084L15.3945 8.20884"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7891 15.1184L15.9052 19.9148L26.1375 10.322"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
