import React, { Suspense, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import GTD from "pages/GTD";
import { Retrospect } from "pages/Retrospect";
import { Route, Routes } from "react-router-dom";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

function App() {
  const isBigDesktop: boolean = useMediaQuery({
    query: "(min-width:1600px)",
  });
  const isDesktop: boolean = useMediaQuery({
    query: "(min-width:1024px)",
  });
  const isTablet: boolean = useMediaQuery({
    query: "(min-width:992px) and (max-width:1023px)",
  });
  const isSmallTablet: boolean = useMediaQuery({
    query: "(min-width:768px) and (max-width:991px)",
  });
  const isMobile: boolean = useMediaQuery({
    query: "(max-width:767px)",
  });

  const location = useLocation();
  const [init, setInit] = React.useState(false);

  const blue = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!;
  //localhost에서는 GA 작동 안하게
  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!);
      setInit(true);
    }
  }, []);

  //location이 바뀔 때마다 pageview 전송
  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }, [init, location]);

  //개발용
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID!);
    ReactGA.set({ page: location.pathname });
    ReactGA.send("pageview");
  }, [location]);

  const LazyGTD = React.lazy(() => import("pages/GTD"));

  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        <Route
          path="/"
          element={
            <LazyGTD
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              isBigDesktop={isBigDesktop}
              isSmallTablet={isSmallTablet}
              // isSmallMobile={isSmallMobile}
            />
          }
        />
        <Route
          path="/retro"
          element={
            <Retrospect
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
            />
          }
        />
        <Route
          path="*"
          element={
            <LazyGTD
              isMobile={isMobile}
              isTablet={isTablet}
              isDesktop={isDesktop}
              isBigDesktop={isBigDesktop}
              isSmallTablet={isSmallTablet}
              // isSmallMobile={isSmallMobile}
            />
          }
        />
      </Routes>

      {/* <GTD isMobile={isMobile} isTablet={isTablet} isDesktop={isDesktop}></GTD> */}
      {/* <Wrapper>
        <Header isMobile={isMobile}>
          <Logo></Logo>
          {!isMobile && (
            <Button
              variant="contained"
              sx={{
                backgroundColor: `${COLORS.brand1}`,
                borderRadius: "8px",
                textTransform: "capitalize",
              }}
              href="https://tally.so/r/mOa0K8"
              target="_blank"
            >
              <HeaderButtonText> Closed Beta 신청하기</HeaderButtonText>
            </Button>
          )}
        </Header>
        <Main>
          <Section className="slash-intro" isMobile={isMobile}>
            <Fade direction="up" triggerOnce={true} duration={1500}>
              <div className="intro-main">
                <Fonts.H1
                  className="intro-main-title"
                  style={{
                    color: `${COLORS.gray900}`,
                    fontSize: `min(6vw, 60px)`,
                    marginRight: isMobile ? "6px" : "16px",
                  }}
                >
                  나를 위한 업무관리 툴,
                </Fonts.H1>
                <div className="intro-main-logo">
                  <Logo
                    width={`min(25vw,212px)`}
                    height={`min(11vw, 70px)`}
                    fill={`${COLORS.gray900}`}
                  ></Logo>
                </div>
              </div>
              <div className="intro-detail">
                <Fonts.H1
                  style={{
                    textAlign: "center",
                    margin: isMobile ? "20px 0px" : "40px 0px",
                    fontWeight: "400",
                    fontSize: `min(4vw, 24px)`,
                    lineHeight: `min(6.5vw, 36px)`,
                  }}
                >
                  SLASH는 개인 생산성을 폭발적으로 높여주는
                  <br></br> <strong>GTD 방법론</strong> 기반 업무관리 툴입니다.
                </Fonts.H1>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Link
                    underline="none"
                    href="https://sigcrew.notion.site/GTD-Get-Things-Done-8b5c0b5beed1475e9f501354bff47fb3"
                    target="_blank"
                    sx={{
                      color: `${COLORS.sub2}`,
                      fontSize: `min(5vw, 20px)`,
                      cursor: "pointer",
                    }}
                  >
                    <strong>GTD 자세히 알아보기</strong>
                  </Link>
                  <div style={{ marginLeft: "10px", marginBottom: "3px" }}>
                    <ArrowRight></ArrowRight>
                  </div>
                </div>
                <Button
                  variant="contained"
                  size="large"
                  href="https://tally.so/r/mOa0K8"
                  target="_blank"
                  sx={{
                    backgroundColor: `${COLORS.brand1}`,
                    marginTop: isMobile ? "40px" : "50px",
                    padding: "16px",
                    borderRadius: "8px",
                    textTransform: "capitalize",
                  }}
                >
                  <MainButtonText>Closed Beta 신청하기</MainButtonText>
                </Button>
              </div>
            </Fade>
            <div>
              <Fade direction="up" triggerOnce={true} duration={1500}>
                <img
                  src={require("./images/main.png")}
                  width="100%"
                  height="auto"
                  style={{
                    zIndex: 1,
                    position: "relative",
                    marginTop: "100px",
                  }}
                ></img>
              </Fade>
            </div>
          </Section>

          <Section className="slash-main" isMobile={isMobile}>
            <Fade
              direction="up"
              triggerOnce={true}
              duration={1500}
              style={{ width: "100%" }}
            >
              <MainContent
                isMobile={isMobile}
                style={{
                  backgroundImage: isMobile
                    ? `url(${require("./images/mobileSlash.png")})`
                    : `url(${require("./images/banner.png")})`,
                  backgroundSize: isMobile ? "contain" : "cover",
                  backgroundPosition: "center",
                }}
              >
                <div className="main-text">
                  <div
                    style={{
                      fontSize: "min(6.8vw,36px)",
                      lineHeight: "min(9.5vw, 50px)",
                    }}
                  >
                    <strong>일 잘 하는 방법</strong>이 궁금한가요?
                  </div>

                  {isMobile ? (
                    <div
                      style={{
                        fontSize: "min(4.6vw, 24px)",
                        lineHeight: "min(7vw, 36px)",
                      }}
                    >
                      협업툴은 팀이 협업을 잘 하도록 돕습니다. <br></br>
                      <strong style={{ color: `${COLORS.gray900}` }}>
                        SLASH
                      </strong>
                      는 프로가 일을 잘 하도록 돕습니다. <br></br>
                      <strong style={{ color: `${COLORS.gray900}` }}>
                        일 잘하는 법
                      </strong>
                      이 녹아든 최신의 업무관리 <br></br>툴을 만나보세요.
                    </div>
                  ) : (
                    <div
                      style={{
                        fontSize: "min(4.6vw, 24px)",
                        lineHeight: "min(7vw, 36px)",
                      }}
                    >
                      협업툴은 팀이 협업을 잘 하도록 돕습니다. <br></br>
                      <strong style={{ color: `${COLORS.gray900}` }}>
                        SLASH
                      </strong>
                      는 프로가 일을 잘 하도록 돕습니다. <br></br>
                      <strong style={{ color: `${COLORS.gray900}` }}>
                        일 잘하는 법
                      </strong>
                      이 녹아든 최신의 업무관리 툴을 만나보세요.
                    </div>
                  )}
                </div>
              </MainContent>
            </Fade>
          </Section>

          <Section className="slash-inbox" isMobile={isMobile}>
            <Inbox isMobile={isMobile} isTablet={isTablet}>
              <div>
                <div className="inbox-text-container">
                  <Fade direction="up" triggerOnce={true}>
                    <div className="feature-text">
                      <Fonts.H1
                        style={{
                          color: `${COLORS.brand1}`,
                          fontSize: "24px",
                        }}
                      >
                        인박스
                      </Fonts.H1>
                    </div>
                    <div className="feature-text">
                      <Fonts.H1
                        style={{
                          fontSize: "min(6.9vw,36px)",
                          lineHeight: "min(9.5vw, 50px)",
                        }}
                      >
                        할 일을 한 곳에 모두 모아두고, <br></br> 머리를 맑게
                        비워요
                      </Fonts.H1>
                    </div>
                    <div
                      className="feature-text"
                      style={{
                        fontSize: "min(4.1vw, 24px)",
                        lineHeight: "min(7vw, 36px)",
                      }}
                    >
                      &apos;이걸 해야하는데...&apos; 하는 생각으로부터 자유롭게.
                      <br></br> 할 일을 모두 적어두고 나중에 쉽게 정리하세요.
                    </div>
                  </Fade>
                </div>
              </div>
              <Fade direction="up" triggerOnce={true} duration={1500}>
                <div className="feature-img">
                  <img
                    src={require("./images/inbox.png")}
                    width="100%"
                    height="auto"
                  ></img>
                </div>
              </Fade>
            </Inbox>
          </Section>

          <Section className="slash-planning" isMobile={isMobile}>
            <Planning isMobile={isMobile} isTablet={isTablet}>
              <div className="planning-text-container">
                <Fade direction="up" triggerOnce={true}>
                  <div className="feature-text">
                    <Fonts.H1
                      style={{
                        color: `${COLORS.brand1}`,
                        fontSize: "24px",
                      }}
                    >
                      주간계획
                    </Fonts.H1>
                  </div>
                  <div className="feature-text">
                    <Fonts.H1
                      style={{
                        fontSize: "min(7vw,36px)",
                        lineHeight: "min(9.5vw, 50px)",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {isMobile
                        ? " 이번 주 가장 중요한 일은 \n 무엇인가요?"
                        : " 이번 주 가장 중요한 일은 무엇인가요?"}
                    </Fonts.H1>
                  </div>
                  <div
                    className="feature-text"
                    style={{
                      fontSize: "min(4.4vw, 24px)",
                      lineHeight: "min(7vw, 36px)",
                    }}
                  >
                    1주, 시간 관리를 위한 가장 중요한 주기. <br></br> 명확한
                    우선순위로 일주일을 알차게 보내세요.
                  </div>
                </Fade>
              </div>

              <div className="planning-img-container">
                <Fade direction="up" triggerOnce={true} duration={1500}>
                  <div className="feature-img">
                    <img
                      src={require("./images/planning.png")}
                      width="100%"
                      height="auto"
                    ></img>
                  </div>
                </Fade>
              </div>
            </Planning>
          </Section>

          <Section className="slash-timeboxing" isMobile={isMobile}>
            <Feature isMobile={isMobile}>
              <div>
                <Fade direction="up" triggerOnce={true}>
                  <div className="feature-text">
                    <Fonts.H1
                      style={{
                        color: `${COLORS.brand1}`,
                        fontSize: "24px",
                      }}
                    >
                      타임박싱
                    </Fonts.H1>
                  </div>
                  <div className="feature-text">
                    <Fonts.H1
                      style={{
                        fontSize: "min(7vw,36px)",
                        lineHeight: "min(9.5vw, 50px)",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {isMobile
                        ? "일 잘 하는 사람의 비법, \n 타임박싱"
                        : "일 잘 하는 사람의 비법, 타임박싱"}
                    </Fonts.H1>
                  </div>
                  <div
                    className="feature-text"
                    style={{
                      fontSize: "min(3.8vw, 24px)",
                      lineHeight: "min(7vw, 36px)",
                    }}
                  >
                    계획은 잘 정리했는데 실천하기는 어려우셨나요? <br></br> 이제
                    계획한 업무를 나와의 약속을 잡듯 실천해보세요.
                  </div>
                </Fade>
              </div>
              <Fade direction="up" triggerOnce={true} duration={1500}>
                <img
                  src={require("./images/timeboxing.png")}
                  width="100%"
                  height="auto"
                  style={{ marginTop: isMobile ? "45px" : "60px" }}
                ></img>
              </Fade>
            </Feature>
          </Section>

          <Section className="slash-linkage" isMobile={isMobile}>
            <Feature isMobile={isMobile} isTablet={isTablet}>
              <div>
                <Fade direction="up" triggerOnce={true}>
                  <div className="feature-text">
                    <Fonts.H1
                      style={{
                        color: `${COLORS.brand1}`,
                        fontSize: "24px",
                      }}
                    >
                      연동
                    </Fonts.H1>
                  </div>
                  <div className="feature-text">
                    <Fonts.H1
                      style={{
                        fontSize: "min(7vw,36px)",
                        lineHeight: "min(9.5vw, 50px)",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {isMobile
                        ? "구글 캘린더, 슬랙과 함께 \n 더 편리하게"
                        : "구글 캘린더, 슬랙과 함께 더 편리하게"}
                    </Fonts.H1>
                  </div>
                </Fade>
              </div>
              <Fade direction="up" triggerOnce={true} duration={1500}>
                <div className="feature-img-container">
                  <div
                    className="feature-img linkage-img"
                    style={{
                      backgroundColor: `${COLORS.gray100}`,
                      marginRight: isDesktop ? "60px" : "0px",
                    }}
                  >
                    <div className="linkage-text">
                      구글 캘린더 일정과 연동할 수 있어요.
                    </div>
                    <div className="linkage-google">
                      <img
                        src={require("./images/google.png")}
                        width="100%"
                        height="auto"
                      ></img>
                    </div>
                  </div>
                  <div
                    className="feature-img linkage-img"
                    style={{ backgroundColor: `${COLORS.gray100}` }}
                  >
                    <div className="linkage-text">
                      업무에 대한 리마인더를 쉽게 설정하고 <br></br> 슬랙으로
                      받아볼 수 있어요.
                    </div>
                    <div className="linkage-slack">
                      <img
                        src={require("./images/slack.png")}
                        width="90%"
                        height="auto"
                      ></img>
                    </div>
                  </div>
                </div>
              </Fade>
            </Feature>
          </Section>

          <Section isMobile={isMobile}>
            <Fade
              direction="up"
              triggerOnce={true}
              duration={1500}
              style={{ width: "100%" }}
            >
              <Register isMobile={isMobile} isTablet={isTablet}>
                <div className="register">
                  <div className="register-title">Closed Beta 신청하기</div>
                  <div className="register-context">
                    {isMobile
                      ? `SLASH는 소수 신청자를 대상으로 하는 \n Closed Beta 버전을 준비하고 있습니다. \n 미리 신청해주시면 Closed Beta  런칭 시 \n 안내 드리겠습니다.`
                      : `SLASH는 소수 신청자를 대상으로 하는 Closed Beta 버전을 준비하고 있습니다. \n 미리 신청해주시면 Closed Beta 런칭 시 안내 드리겠습니다.`}
                  </div>
                  <Button
                    variant="contained"
                    size="large"
                    href="https://tally.so/r/mOa0K8"
                    target="_blank"
                    sx={{
                      backgroundColor: `${COLORS.brand1}`,
                      padding: "16px",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                    }}
                  >
                    <MainButtonText>Closed Beta 신청하기</MainButtonText>
                  </Button>
                </div>
              </Register>
            </Fade>
          </Section>
        </Main>

        <Footer isMobile={isMobile}>
          <div style={{ width: isMobile ? "100%" : "70%" }}>
            <Logo fill={COLORS.white}></Logo>
            <div className="footer-context">
              <div style={{ marginBottom: "12px" }}>
                3x Output, 30% less Effort
              </div>
              <div style={{ whiteSpace: "pre-line" }}>
                {isMobile
                  ? `어떤 프로든 3배의 성과를 \n 30% 적은 노력으로 성취하게 돕습니다`
                  : `어떤 프로든 3배의 성과를 30% 적은 노력으로 성취하게 돕습니다`}
              </div>
            </div>
            <div className="divider"></div>
            <div className="footer-bar">
              <div className="footer-bar-text">
                <Link
                  underline="none"
                  href="http://policy.doslash.io/"
                  target="_blank"
                  sx={{ cursor: "pointer", color: `${COLORS.white}` }}
                >
                  Terms of Service
                </Link>
                <Link
                  underline="none"
                  href="#"
                  target="_blank"
                  sx={{ cursor: "pointer", color: `${COLORS.white}` }}
                >
                  Privacy Policy
                </Link>
                <Link
                  underline="none"
                  href="https://www.sigcrew.com/"
                  target="_blank"
                  sx={{ cursor: "pointer", color: `${COLORS.white}` }}
                >
                  Company Information
                </Link>
              </div>
              <div
                className="footer-bar-copyright"
                style={{ whiteSpace: "pre-line" }}
              >
                Copyright © 2023 SIG. All Rights Reserved.
              </div>
            </div>
          </div>
        </Footer>
      </Wrapper> */}
    </Suspense>
  );
}

export default App;
