import React, { SVGProps } from "react";

export const QuoteLeft = ({
  width = 49,
  height = 42,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 49 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1895_2599)">
        <path
          d="M22.6154 24.8182V36.2727C22.6154 37.8636 22.0657 39.2159 20.9663 40.3295C19.867 41.4432 18.5321 42 16.9615 42H5.65385C4.08333 42 2.7484 41.4432 1.64904 40.3295C0.54968 39.2159 0 37.8636 0 36.2727V15.2727C0 13.2045 0.397536 11.2308 1.19261 9.35156C1.98768 7.4723 3.0625 5.84659 4.41707 4.47443C5.77163 3.10227 7.3765 2.01349 9.23167 1.2081C11.0868 0.402699 13.0353 0 15.0769 0H16.9615C17.472 0 17.9137 0.18892 18.2867 0.566761C18.6597 0.944602 18.8462 1.39205 18.8462 1.90909V5.72727C18.8462 6.24432 18.6597 6.69176 18.2867 7.0696C17.9137 7.44744 17.472 7.63636 16.9615 7.63636H15.0769C12.996 7.63636 11.2194 8.3821 9.74699 9.87358C8.27464 11.3651 7.53846 13.1648 7.53846 15.2727V16.2273C7.53846 17.0227 7.8133 17.6989 8.36298 18.2557C8.91266 18.8125 9.58013 19.0909 10.3654 19.0909H16.9615C18.5321 19.0909 19.867 19.6477 20.9663 20.7614C22.0657 21.875 22.6154 23.2273 22.6154 24.8182ZM49 24.8182V36.2727C49 37.8636 48.4503 39.2159 47.351 40.3295C46.2516 41.4432 44.9167 42 43.3462 42H32.0385C30.4679 42 29.133 41.4432 28.0337 40.3295C26.9343 39.2159 26.3846 37.8636 26.3846 36.2727V15.2727C26.3846 13.2045 26.7822 11.2308 27.5772 9.35156C28.3723 7.4723 29.4471 5.84659 30.8017 4.47443C32.1562 3.10227 33.7611 2.01349 35.6163 1.2081C37.4715 0.402699 39.4199 0 41.4615 0H43.3462C43.8566 0 44.2983 0.18892 44.6713 0.566761C45.0443 0.944602 45.2308 1.39205 45.2308 1.90909V5.72727C45.2308 6.24432 45.0443 6.69176 44.6713 7.0696C44.2983 7.44744 43.8566 7.63636 43.3462 7.63636H41.4615C39.3806 7.63636 37.604 8.3821 36.1316 9.87358C34.6593 11.3651 33.9231 13.1648 33.9231 15.2727V16.2273C33.9231 17.0227 34.1979 17.6989 34.7476 18.2557C35.2973 18.8125 35.9647 19.0909 36.75 19.0909H43.3462C44.9167 19.0909 46.2516 19.6477 47.351 20.7614C48.4503 21.875 49 23.2273 49 24.8182Z"
          fill="#E2ECFF"
        />
      </g>
      <defs>
        <clipPath id="clip0_1895_2599">
          <rect width="49" height="42" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
