import React, { SVGProps } from "react";

export const Memo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.127 22.75V9.25C26.127 7.98887 25.1381 7 23.877 7C22.6158 7 21.627 7.98887 21.627 9.25V22.75L23.877 25L26.127 22.75Z"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.627 11.499H26.127"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.875 24.9995H9.25C8.65326 24.9995 8.08097 24.7625 7.65901 24.3405C7.23705 23.9185 7 23.3463 7 22.7495C7 22.1528 7.23705 21.5805 7.65901 21.1585C8.08097 20.7366 8.65326 20.4995 9.25 20.4995H13.75C14.3467 20.4995 14.919 20.2625 15.341 19.8405C15.7629 19.4185 16 18.8462 16 18.2495C16 17.6528 15.7629 17.0805 15.341 16.6585C14.919 16.2366 14.3467 15.9995 13.75 15.9995H10.375"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
