import React, { SVGProps } from "react";

export const Close = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 6L17.9999 17.9999"
        stroke="#1F2023"
        strokeWidth="2.25"
        strokeLinecap="round"
      />
      <path
        d="M18 6L6.0001 17.9999"
        stroke="#1F2023"
        strokeWidth="2.25"
        strokeLinecap="round"
      />
    </svg>
  );
};
