export const COLORS = {
  brand1: "#0039A7",
  sub1: "#002E87",
  sub2: "#2D6ADF",
  sub3: "#E2ECFF",
  sub4: "#10B8C2",
  sub5: "#E9F9FA",
  positive1: "#00E19E",
  positive2: "#E5FCF5",
  negative1: "#D8204C",
  negative2: "#FCDFE6",
  alert1: "#FFCC4D",
  alert2: "#FFF6DF",
  gray900: "#1F2023",
  gray800: "#34363B",
  gray700: "#565860",
  gray600: "#656871",
  gray500: "#858994",
  gray400: "#ABB0BF",
  gray300: "#C8CDDB",
  gray200: "#E7EAF4",
  gray100: "#F2F5FC",
  black: "#000000",
  white: "#FFFFFF",
  shadow100: "rgba(26, 30, 39, 0.16);",
};
