import React, { SVGProps } from "react";

export const Reminder = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.125 9.75C14.125 9.25272 14.3225 8.77581 14.6742 8.42417C15.0258 8.07254 15.5027 7.875 16 7.875C16.4973 7.875 16.9742 8.07254 17.3258 8.42417C17.6775 8.77581 17.875 9.25272 17.875 9.75C18.9516 10.2591 19.8694 11.0516 20.53 12.0425C21.1907 13.0334 21.5692 14.1854 21.625 15.375V18.1875C21.6956 18.7703 21.902 19.3285 22.2276 19.817C22.5533 20.3055 22.9891 20.7107 23.5 21H8.5C9.01088 20.7107 9.4467 20.3055 9.77236 19.817C10.098 19.3285 10.3044 18.7703 10.375 18.1875V15.375C10.4308 14.1854 10.8093 13.0334 11.47 12.0425C12.1306 11.0516 13.0484 10.2591 14.125 9.75"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 21V21.7812C13.5 22.4029 13.7634 22.999 14.2322 23.4385C14.7011 23.8781 15.337 24.125 16 24.125C16.663 24.125 17.2989 23.8781 17.7678 23.4385C18.2366 22.999 18.5 22.4029 18.5 21.7812V21"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
