import React, { SVGProps } from "react";

export const TaskGrouping = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.4355 16.5414L14.7009 19.8068L21.2315 13.2761"
        stroke="#1F2023"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="7.28906"
        y="7.49609"
        width="18.0919"
        height="18.0919"
        stroke="#1F2023"
        strokeWidth="1.5"
      />
      <circle cx="7.35329" cy="7.56252" r="2.44899" fill="#1F2023" />
      <circle cx="25.3123" cy="7.56252" r="2.44899" fill="#1F2023" />
      <circle cx="25.3123" cy="25.5217" r="2.44899" fill="#1F2023" />
      <circle cx="7.35329" cy="25.5217" r="2.44899" fill="#1F2023" />
    </svg>
  );
};
